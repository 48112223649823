<template>
  <div>
    <h2 class="text-center info-title"><strong>Quiénes Somos</strong></h2>
    <br />
    <h3 class="text-center info-title"><strong>Propósito</strong></h3>
    <p class="text-center secondary-title">
      <strong
        ><i
          >Mejorar la educación y formación profesional de las IES, demostrando
          que forman profesionales competentes.</i
        ></strong
      >
    </p>
    <div
      class="main-info"
      style="border-bottom: 1px solid #14aab6; margin: 0 50px"
    >
      <div class="main-info-picture">
        <img class="w-50" src="@/assets/ico_1.png" alt="" />
      </div>
      <div class="main-info-content" style="text-align: justify">
        <h4 class="info-title"><strong>Misión</strong></h4>
        <p class="content-body">
          Contribuir con las Instituciones de Educación Superior en la
          implementación de la gestión de Resultados de Aprendizaje de
          estudiantes con apoyos tecnológicos, que faciliten la articulación
          curricular y pedagógica, la evaluación y seguimiento al logro de las
          competencias de los perfiles de egreso de las carreras. Además de
          apoyar, asesorar y capacitar en la generación de mecanismos académicos
          para asegurar la calidad en los procesos formativos.
        </p>
      </div>
    </div>
    <div
      class="main-info ml-5"
      style="border-bottom: 1px solid #14aab6; margin: 0 50px"
    >
      <div class="main-info-content my-5" style="text-align: justify">
        <h4 class="info-title"><strong>Visión</strong></h4>
        <p class="content-body">
          Lograr ser reconocidos como una empresa que apoya realmente a las
          Instituciones de Educación Superior para alcanzar procesos formativos
          pertinentes, de calidad y evaluando sistemáticamente resultados.
        </p>
      </div>
      <div class="main-info-picture">
        <img class="w-50" src="@/assets/ico_2.png" alt="" />
      </div>
    </div>
    <div class="main-info">
      <div class="main-info-picture">
        <img class="w-50" src="@/assets/ico_3.png" alt="" />
      </div>
      <div class="main-info-content mt-2" style="text-align: justify">
        <h4 class="info-title"><strong>Origen de la empresa</strong></h4>
        <p class="content-body">
          Key Learning SpA es una empresa chilena, con proyección hacia
          Latinoamérica, que nace para satisfacer soluciones en las
          Instituciones de Educación Superior relacionadas con los modelos
          formativos, incorporando tecnologías, proceso y mecanismos que
          aseguren la calidad y pertinencia, conforme sus propios proyectos
          educativos y las exigencias de criterios y estándares de los sistemas
          nacionales e internacionales de aseguramiento de la calidad.
        </p>
        <p class="content-body">
          El equipo que conforma la empresa esta constituido por profesionales y
          académicos con vasta experiencia en la Gestión Académica en la
          Educación Superior, trabajando en el diseño, implementación y
          capacitación de modelos educativos centrados en el estudiante,
          generando mecanismos que aseguran la ejecución de innovaciones
          curriculares integradas con el trabajo pedagógico del claustro
          académico.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "About",
  mounted() {},
};
</script>

<style scoped>
h2 {
  margin-top: 1rem;
}
h4 {
  margin-left: -4% !important;
}
.secondary-title {
  font-size: var(--primary-font-size);
}
.content-body {
  font-size: var(--primary-font-size);
}
/* @media (max-width: 1025px) {
  .main-info {
    display: block;
  }
  .main-info-picture {
    display: none;
  }
  .main-info-content {
    width: 100% !important;
  }
} */
</style>